@import "@/style/index.scss";

.h1 {
  @include large-header();
  &__underText {
    width: 100%;
    font-weight: 700;
    position: relative;
    &__decoration {
      display: none;
      width: 100%;
      position: absolute;
      left: -25rem;
      top: 4rem;
      width: 50rem;
      border-radius: 1rem;
      height: 2rem;
      background-color: #007acc8f;
      animation: spanAnimation 3s ease-out;
      @media screen and (min-width: $desktop) {
        display: block;
      }
    }
  }
}

@keyframes spanAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 50rem;
  }
}
