@import "@/style/index.scss";

.homeContainer {
  padding-top: 2rem;
  scroll-margin-top: 10rem;
  background-color: $dark-white-background-color;
  @media screen and (min-width: $big-tablet) {
    width: 100vw;
  }
}
