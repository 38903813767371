@import "@/style/index.scss";

.img {
  width: 90%;
  height: 90%;
  border-radius: 30%;
  display: none;
  @media screen and (min-width: $desktop) {
    display: block;
    width: 30%;
    margin-right: 2rem;
  }
  @media screen and (min-width: 1450px) {
    margin-right: 15rem;
    margin-top: 1rem;
  }
}
